<template>
  <div class="wiki-main">
    wiki main
  </div>
</template>

<script>
export default {
  name: 'WikiMain',
};
</script>

<style lang="scss">
.wiki-main {
  width: 100%;
}
</style>
